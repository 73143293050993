<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <div class="bordered pad">
          <h1>Add Coursework</h1>
        </div>
      </div>
    </div>
    <div class="grid-x grid-padding-x pad-top">
      <div class="cell small-12">
        <div class="bordered pad">
          <form class="worksheet-form">
            <div class="grid-x grid-padding-x">
              <div class="cell small-12 medium-6">
                <label>Title<sup class="text-red">*</sup></label>
                <select v-model="worksheet.title">
                  <option
                    v-for="(heading, index) in headings"
                    v-bind:value="heading.heading"
                    v-bind:key="index">
                    {{ heading.heading }}
                  </option>
                </select>
                <label>Notes</label>
                <vue-editor
                  id="notesEditor"
                  v-bind:editorToolbar="customToolbar"
                  v-model="worksheet.notes"
                  placeholder="Notes text"
                  rows="12">
                </vue-editor>
              </div>
              <div class="cell small-12 medium-6">
                <label>Click the button below to select coursework to upload</label>
                <div class="worksheet-one"></div>
                <div><p>{{ filename }}</p></div>
                <button class="button">Save</button>
                <div class="UppyProgressBar"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
      <span class="close-message" v-on:click="reload()">X</span>
      <p class="message-text">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

const Uppy = require('@uppy/core');
const UppyForm = require('@uppy/form');
const Transloadit = require('@uppy/transloadit');
const ProgressBar = require('@uppy/progress-bar');
const FileInput = require('@uppy/file-input');

export default {
  name: 'AddWorksheet',
  data() {
    return {
      headings: [],
      worksheet: {
        title: '',
        myTitle: '',
        notes: '',
        worksheetOneName: '',
      },
      filename: '',
      message: '',
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: '1' }, { header: '2' }],
        ['link'],
      ],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    courseChosen() {
      return this.$store.getters.courseChosen;
    },
  },
  methods: {
    getHeadings() {
      axios.get(`/worksheets/getHeadings.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&course=${this.courseChosen}`)
        .then((response) => {
          this.headings = response.data.headings;
        });
    },
    saveWorksheet(result) {
      this.worksheet.files = result;
      this.worksheet.course = this.courseChosen;
      axios.post(`/worksheets/add.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`, this.worksheet)
        .then((response) => {
          if (response.data.response === true) {
            this.message = 'The work sheet has been added successfully';
          } else {
            this.message = 'The work sheet could not be added - please check the details';
          }
        });
    },
    reload() {
      // eslint-disable-next-line
      location.reload();
    },
    clearMessage() {
      this.message = '';
    },
  },
  mounted() {
    this.getHeadings();
    Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: (1000000 * 1000),
        maxNumberOfFiles: 3,
        minNumberOfFiles: 0,
      },
    })
      .use(UppyForm, {
        target: '.worksheet-form',
        multipleResults: true,
        triggerUploadOnSubmit: true,
        submitOnSuccess: false,
      })
      .use(FileInput, {
        id: 'WorksheetInputOne',
        target: '.worksheet-one',
        pretty: true,
        inputName: 'worksheet',
      })
      .on('file-added', (res) => {
        this.filename = res.name;
      })
      .use(ProgressBar, {
        target: '.UppyProgressBar',
        hideAfterFinish: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: 'ad27d2c2cad8454fba2791c04c58c00e',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
      })
      .on('complete', (result) => {
        this.saveWorksheet(result);
      });
  },
};
</script>
